import { faCircleDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap"
import Moment from "react-moment"
import Viewer from "react-viewer"
import { getFileReadUrlQuery } from "../../../services/CelebMedicalFile.Service"
import { getPatientTreatmentFilesQuery } from "../../../services/CelebTreatmentFiles.Service"
import { useSidebarStore } from "../../../store/sideBarStore"
import {
	sidebarPhotosLibrary as photosLibrary,
	sidebarPhotosLibraryReset as photosLibraryReset,
} from "../../../utils/SidebarphotoFileShowCase"
import { downloadFile } from "../../../utils/utilFunctions"
import { fileResult } from "../../dialogs/replaceOrder-v2/PatientPhotos.tab"
import { CheckGenericPermission } from "../../permissions/CheckPermision"
import { fileReadableName, infoMapperNames } from "../../utlity/GenricFunctions"
import "./sidebar.css"

const Records = () => {
	const sideBarData = useSidebarStore()
	const images: { src: string; alt?: string }[] = []
	const comImages: ImageDetailsType[] = []
	const [ visible, setVisible ] = useState( {
		show: false,
		key: 0,
		photos: "",
	} )
	const { data: fileData } = getPatientTreatmentFilesQuery(
		sideBarData?.sideBarPatientData?.PatientData?.id,
	)

	React.useEffect( () => {
		return photosLibraryReset()
	}, [ sideBarData ] )

	const treatmentFiles = fileData.filter( ( file: fileResult ) => file.fileCategory !== "editor_stl" )

	fileData.forEach( ( file: fileResult ) => {
		if ( file.fileCategory.includes( "photo" ) ) {
			const imageUri = getFileReadUrlQuery( file.fileUri )
			const photo = { src: imageUri.data, alt: file.fileSubCategory }
			photosLibrary[ file.fileSubCategory ] = imageUri.data
			images.push( photo )
		}
	} )

	comImages.push( { src: photosLibrary[ "patient_photo_composite" ] ?? "", alt: "Composite Photo" } )
	treatmentFiles.forEach( ( file: fileResult ) => {
		if ( file.fileCategory.includes( "patient_xray" ) ) {
			const imageUri = getFileReadUrlQuery( file.fileUri )
			comImages.push( {
				src: imageUri.data,
				alt: file.fileSubCategory || "X-ray",
				updatedAt: file.updatedAt,
			} )
		}
	} )

	const tooltip = ( data: ImageDetailsType ) => {
		const uploadedBy =
			"Dr." + infoMapperNames( sideBarData.sideBarPatientData.PatientData.doctor, [ "id" ] )
		const uploadedOn = (
			<Moment format="MM/DD/YYYY hh:mma" local>
				{data.updatedAt}
			</Moment>
		)
		return (
			<Tooltip id="tooltip">
				<div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
					<div>
						<strong>Uploaded By:</strong> {uploadedBy}
					</div>
					<div>
						<strong>Uploaded On:</strong> {uploadedOn}
					</div>
				</div>
			</Tooltip>
		)
	}

	const ShowPicture = () => {
		return (
			<>
				<div style={{ backgroundColor: "#d9d9d9" }}>
					{comImages.map( ( image, index ) => (
						<OverlayTrigger placement="top" overlay={tooltip( image )}>
							<img
								src={image.src}
								hidden={!image.src}
								className="mb-2"
								style={{ height: "100%", width: "100%", borderRadius: "10px" }}
								onClick={() => setVisible( { show: true, key: index, photos: "composite" } )}
							/>
						</OverlayTrigger>
					) )}
				</div>
			</>
		)
	}

	return (
		<main
			style={{
				backgroundColor: "#d9d9d9",
				width: "-webkit-fill-available",
				borderRadius: "10px",
				overflow: "scroll",
				scrollbarWidth: "none",
			}}
			className="p-3"
		>
			{!photosLibrary[ "patient_photo_composite" ] && (
				<>
					<CheckGenericPermission genericLabel="Treatment Photo Individual">
						<div>
							<Table variant="secondary" responsive className="mt-2">
								<tbody>
									<center>
										<tr className="border-0">
											<td>
												<img
													src={photosLibrary[ "patient_photo_face_side" ]}
													className="imgGridPlace "
													onClick={() =>
														images[ 0 ]
															? setVisible( { show: true, key: 0, photos: "individual" } )
															: ""
													}
												/>
											</td>
											<td>
												<img
													src={photosLibrary[ "patient_photo_face_front" ]}
													className="imgGridPlace "
													onClick={() =>
														images[ 0 ]
															? setVisible( { show: true, key: 1, photos: "individual" } )
															: ""
													}
												/>
											</td>
											<td>
												<img
													src={photosLibrary[ "patient_photo_face_front_teeth" ]}
													className="imgGridPlace "
													onClick={() =>
														images[ 0 ]
															? setVisible( { show: true, key: 2, photos: "individual" } )
															: ""
													}
												/>
											</td>
										</tr>
										<tr className="border-0">
											<td>
												<img
													src={photosLibrary[ "patient_photo_jaw_upper" ]}
													className="imgGridPlace "
													onClick={() =>
														images[ 0 ]
															? setVisible( { show: true, key: 3, photos: "individual" } )
															: ""
													}
												/>
											</td>
											<td>
												<img src={photosLibrary[ "" ]} className="imgGridPlace " />
											</td>
											<td>
												<img
													src={photosLibrary[ "patient_photo_jaw_lower" ]}
													className="imgGridPlace "
													onClick={() =>
														images[ 0 ]
															? setVisible( { show: true, key: 4, photos: "individual" } )
															: ""
													}
												/>
											</td>
										</tr>
										<tr className="border-0">
											<td>
												<img
													src={photosLibrary[ "patient_photo_jaw_right" ]}
													className="imgGridPlace "
													onClick={() =>
														images[ 0 ]
															? setVisible( { show: true, key: 5, photos: "individual" } )
															: ""
													}
												/>
											</td>
											<td>
												<img
													src={photosLibrary[ "patient_photo_jaw_front" ]}
													className="imgGridPlace "
													onClick={() =>
														images[ 0 ]
															? setVisible( { show: true, key: 6, photos: "individual" } )
															: ""
													}
												/>
											</td>
											<td>
												<img
													src={photosLibrary[ "patient_photo_jaw_left" ]}
													className="imgGridPlace "
													onClick={() =>
														images[ 0 ]
															? setVisible( { show: true, key: 7, photos: "individual" } )
															: ""
													}
												/>
											</td>
											<td></td>
										</tr>
									</center>
								</tbody>
							</Table>
						</div>
					</CheckGenericPermission>
				</>
			)}
			<Viewer
				visible={visible.show}
				onClose={() => {
					setVisible( { show: false, key: 0, photos: "" } )
				}}
				images={visible.photos === "individual" ? images : comImages}
				downloadable
				activeIndex={visible.key}
				showTotal
			/>
			{<ShowPicture />}
			<hr />
			<text className="sidebar-subject">Files:</text>
			<br />
			<div>
				{treatmentFiles.map( ( file: fileResult, index: number ) => {
					if ( !file.fileCategory.includes( "photo" ) && !file.fileCategory.includes( "xray" ) ) {
						return (
							<CheckGenericPermission genericLabel="Treatment Files">
								<div
									className="file-item mb-2 p-2 border rounded"
									key={index}
									onClick={async () => {
										sideBarData.addDownloadingFile( index )
										const result = await downloadFile( file.fileUri, file.originalFileName )
										if ( result ) {
											sideBarData.removeDownloadingFile( index )
										}
									}}
								>
									<span className="icon-container">
										{sideBarData.downloadingFileIndexes.includes( index ) ? (
											<div className="spinner-border text-secondary" role="status">
												<span className="sr-only">Loading...</span>
											</div>
										) : (
											<FA icon={faCircleDown} style={{ fontSize: "35px", color: "#8faadc" }} />
										)}
									</span>

									<span className="flex-grow-1">
										<div className="file-name">{fileReadableName( file.fileSubCategory )}</div>
										<div className="doctor-name">
											{"Dr." +
												infoMapperNames( sideBarData.sideBarPatientData.PatientData.doctor, [ "id" ] )}
										</div>
									</span>
									<span className="date-container text-end">
										<div className="date-text">
											<Moment format="MM/DD/YYYY hh:mma" local>
												{file.updatedAt}
											</Moment>
										</div>
									</span>
								</div>
							</CheckGenericPermission>
						)
					}
				} )}
			</div>
		</main>
	)
}

export type ImageDetailsType = {
	src: string
	alt?: string
	updatedAt?: string
}

export default Records
