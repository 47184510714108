import React from "react"
import { Form, FormControlProps, InputGroup } from "react-bootstrap"
export const ServicePricingSetter = ( {
	name,
	formControlAccess,
	error = false,
}: {
	name: string
	formControlAccess: FormControlProps
	error?: boolean
} ) => {
	return (
		<React.Fragment>
			<div>
				<span>{name}</span>
				<div className="d-flex justify-content-evenly">
					<InputGroup className="mb-3">
						<InputGroup.Text>$</InputGroup.Text>
						<Form.Control
							type="number"
							aria-label="Amount (to the nearest dollar)"
							isInvalid={error}
							{...formControlAccess}
						/>
					</InputGroup>
				</div>
			</div>
		</React.Fragment>
	)
}
