import { Button, Container, Navbar } from "react-bootstrap"
import Moment from "react-moment"
import { Params, useParams, useSearchParams } from "react-router-dom"
import generatePDF from "react-to-pdf"
import {
	PaymentData,
	useServicefetchDetails,
	useServicePricingsfetchDetails,
} from "../../services/CelebService.Service"
import { getPatientByIdQuery } from "../../services/CelebTreatment.Service"
import { JawType } from "../../utils/appConstants"
import "./receiptGenerator.css"

const ReceiptGenerator = () => {
	return (
		<>
			<NavbarReciept />
			<div id="content-id" className="d-flex">
				<Receipt />
			</div>
		</>
	)
}

const Receipt = () => {
	const paramsData: Readonly<Params<string>> = useParams()
	const [ searchParams ] = useSearchParams()
	const newParams = new URLSearchParams( searchParams )
	const { data: defaultValuesEdit } = getPatientByIdQuery( paramsData.id ?? "null" )
	const { data: data } = useServicePricingsfetchDetails( paramsData.id )
	const serviceId = newParams.get( "serviceId" )
	const { data: serviceData } = useServicefetchDetails( serviceId! )
	const serviceAddress = serviceData.services.address?.[ 0 ]
	const descriptionMap: { [key in JawType]: string } = {
		[ JawType.BOTH ]: "Upper and Lower Both Braces",
		[ JawType.LOWER ]: "Lower Braces",
		[ JawType.UPPER ]: "Upper Braces",
	}
	const description =
		descriptionMap[ data.treatmentPaymentTreatment.treatmentType as JawType ] || "Celebrace Braces"
	const calculateActualPrice = ( data: PaymentData ) => {
		const taxPercentage = data?.taxBreakdown?.[ 0 ]?.percentage
		const amountWithTax = data?.amount
		const actualPrice = amountWithTax / ( 1 + taxPercentage / 100 )
		return Math.round( actualPrice * 100 ) / 100
	}

	const renderAddress = ( address: AddressRenderType ) => {
		if ( !address ) return null
		return (
			<>
				<div>{address.name}</div>
				<div>{address.addressLine1}</div>
				<div hidden={!address.addressLine2 || address.addressLine2 != null}>
					{address.addressLine2 ?? ""}
				</div>
				<div>
					{address.city}, {address.state}, {address.pincode}
				</div>
			</>
		)
	}

	const renderField = ( value: string | number ) => {
		return value ? value : "-"
	}

	return (
		<Container style={{ border: "2px solid #d9d9d9", padding: "5rem", marginTop: "2rem" }}>
			<div style={{ display: "flex", marginBottom: "2%", justifyContent: "space-between" }}>
				<div>
					<h1>Celebrace</h1>
					{serviceAddress ? (
						<>
							<div>
								{renderField( serviceAddress.name )}, {renderField( serviceAddress.addressLine1 )}
							</div>
							<div>
								{renderField( serviceAddress.addressLine2 )}, {renderField( serviceAddress.city )},{" "}
								{renderField( serviceAddress.state )},{renderField( serviceAddress.country )},{" "}
								{renderField( serviceAddress.pincode )}
							</div>
						</>
					) : (
						<>
							<div>4901 Keller Springs Rd</div>
							<div>#102 Addison, TX 75001</div>
						</>
					)}

					<span>
						Email :<a href="mailto:support@celebrace.com">support@celebrace.com</a>{" "}
					</span>
					<div>Phone :3124791449</div>
				</div>
				<div>
					<img
						src={`${process.env.PUBLIC_URL}/celebLogo.png`}
						height={100}
						width={100}
						alt="Celebrace Logo"
					/>
				</div>
				<div>
					<h1 className="text-end">RECEIPT</h1>
				</div>
			</div>
			<hr />
			<div className="mt-5">
				<div className="d-flex justify-content-between">
					<div>
						<strong className="mb-3">Bill To:</strong>
						<div>
							Dr. {defaultValuesEdit?.doctor?.firstName + " " + defaultValuesEdit?.doctor?.lastName}
						</div>
						<div>
							{renderField(
								( defaultValuesEdit?.treatmentPlanDoctorAddress?.addressLine1 || "" ) +
									( defaultValuesEdit?.treatmentPlanDoctorAddress?.addressLine2 == null
										? `, ${defaultValuesEdit.treatmentPlanDoctorAddress.addressLine2}`
										: "" ),
							)}
						</div>
						<div>
							{renderField(
								defaultValuesEdit?.treatmentPlanDoctorAddress?.city +
									", " +
									defaultValuesEdit?.treatmentPlanDoctorAddress?.state +
									", " +
									defaultValuesEdit?.treatmentPlanDoctorAddress?.pincode,
							)}
						</div>
					</div>
					<div>
						<strong>Ship To:</strong>
						{renderAddress( defaultValuesEdit?.treatmentPlanDoctorAddress )}
					</div>
					<div>
						<div className="d-flex justify-content-between">
							<b>Receipt # </b>
							<p className="mx-2"> CB-{data.id.substring( 0, 4 ).toUpperCase()}</p>
						</div>
						<div className="d-flex justify-content-between">
							<b>Invoice Date </b>
							<p className="mx-2">
								<Moment format="MM/DD/YYYY" utc>
									{data.createdAt}
								</Moment>
							</p>
						</div>
						<div className="d-flex justify-content-between">
							<b>P.O.# </b>
							<p className="mx-2"></p>
						</div>
					</div>
				</div>
				<table style={{ width: "100%", padding: "2px" }} className="mt-4">
					<thead>
						<tr className="myRow">
							<th className="center myHead myRow">QTY</th>
							<th className="center myHead myRow">Description</th>
							<th className="center myHead myRow">Unit Price</th>
							<th className="center myHead myRow">Amount</th>
						</tr>
					</thead>
					<tbody>
						<tr className="myRow">
							<td className="center">1</td>
							<td className="left"> {description}</td>
							<td className="myCol">$ {renderField( calculateActualPrice( data ) )}</td>
							<td>$ {renderField( calculateActualPrice( data ) )}</td>
						</tr>
						<tr>
							<td className="center"></td>
							<td className="left"></td>
							<td className="myRow">SubTotal</td>
							<td className="myRow">$ {renderField( calculateActualPrice( data ) )}</td>
						</tr>
						<tr>
							<td className="center"></td>
							<td className="left"></td>
							<td className="myRow">Sales Tax {data?.taxBreakdown?.[ 0 ]?.percentage}%</td>
							<td className="myRow">$ {renderField( data?.taxBreakdown?.[ 0 ]?.amount )}</td>
						</tr>
						<tr>
							<td className="center"></td>
							<td className="left"></td>
							<td className="myRow">
								<b>Total</b>
							</td>
							<td className="myRow">
								<b>$ {renderField( data?.amount )}</b>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</Container>
	)
}

const NavbarReciept = () => {
	const handleGoBack = () => {
		window.close()
	}
	const getTargetElement = () => document.getElementById( "content-id" )
	return (
		<Navbar className="bg-body-tertiary justify-content-between" style={{ width: "100%" }}>
			<div>
				<Button onClick={( e ) => handleGoBack()}>Close</Button>
			</div>

			<div>
				<Button onClick={() => generatePDF( getTargetElement )}>Download</Button>
			</div>
		</Navbar>
	)
}

export type AddressRenderType = {
	name: string
	addressLine1: string
	addressLine2: string
	city: string
	state: string
	pincode: string
}

export default ReceiptGenerator
