import React from "react"
import { Alert, Button, Modal, Nav, Navbar, Spinner } from "react-bootstrap"
import { FieldErrors, useFormContext } from "react-hook-form"
import { useLocation } from "react-router"
import { queryClient } from "../../../../providers/QueryProvider"
import { useCreateServiceMutation } from "../../../../services/CelebService.Service"
import { useServiceDialogStore } from "../../../../store/serviceDialogStore"
import { clearFormState, clearHash } from "../../../utlity/GenricFunctions"
import { ServiceFormType } from ".././serviceFunctions/ServiceFormFields"
import { uploadLogo } from "../../../utlity/uploadFiles"

export const ServiceNavbar = React.memo( () => {
	const locationHash = useLocation()
	const serviceStore = useServiceDialogStore()
	const { mutateAsync: creatServiceAsync } = useCreateServiceMutation()
	const form = JSON.parse( localStorage.getItem( "serviceData" ) + "" )
	const clearHashClear = clearHash()
	const { setValue, getValues, handleSubmit, formState, watch, unregister } =
		useFormContext<ServiceFormType>()
	const saveService = async ( value: ServiceFormType ) => {
		const logo = watch( "logo" )
		const logoUrl = logo != null ? await uploadLogo( logo ) : ""
		const formDataObj = {
			...value,
			categories: [
				...serviceStore.serviceConsumerCategories,
				...serviceStore.serviceProviderCategories,
			],
			logo: logoUrl,
			address: getValues( "address" ),
		}
		try {
			const result = await creatServiceAsync( formDataObj )
			if ( result.data.status === 201 || result.data.status === 200 ) {
				return closeService()
			}
			return serviceStore.setServiceError( result?.data?.message )
		} catch ( err ) {
			console.error( "Error creating service:", err )
		}
	}

	const closeService = () => {
		serviceStore.setServiceError( "" )
		serviceStore.resetTaskForms()
		clearFormState( form, unregister )
		clearHashClear()
		queryClient.invalidateQueries()
	}

	const onSubmitError = ( errors2: FieldErrors<ServiceFormType> ) => {
		console.error( { errors2 } )
		setValue( "taskErrors", errors2.tasks?.message )
	}

	return (
		<React.Fragment>
			<Navbar id="navbarScroll" as={Modal.Header}>
				<Nav variant="tabs" defaultActiveKey="#ServiceInfo" className="d-flex mt-2 me-auto">
					<Nav.Link className="" active={locationHash.hash == "#ServiceInfo"} href="#ServiceInfo">
						Create Service
					</Nav.Link>
					<Nav.Link active={locationHash.hash == "#Tasks"} href="#Tasks">
						Tasks
					</Nav.Link>
				</Nav>
				<span>
					<Button type="submit" onClick={handleSubmit( saveService, onSubmitError )}>
						{formState.isSubmitting ? (
							<>
								<Spinner animation="border" role="status" size="sm" /> Saving..{" "}
							</>
						) : (
							"Save"
						)}
					</Button>
					<Button
						variant="secondary"
						className="mx-2"
						onClick={() => serviceStore.setCloseModalDialog( true )}
					>
						Cancel
					</Button>
				</span>
			</Navbar>
			<Alert variant="danger" className="m-2" hidden={!serviceStore.serviceError}>
				{serviceStore.serviceError}
			</Alert>
		</React.Fragment>
	)
} )
