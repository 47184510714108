import { create } from "zustand"
import { ReactSelectCreatableCustom } from "../services/CelebService.Service"

type EditId = string | null

interface ServiceDialogState {
	hasMapped: boolean
	setHasMapped: ( next: boolean ) => void

	listOpen: boolean
	setListOpen: ( next: boolean ) => void

	editOpen: EditId
	setEditOpen: ( next: EditId ) => void

	createOpen: boolean
	setCreateOpen: ( next: boolean ) => void

	editAddressOpen: boolean
	setEditAddressOpen: ( next: boolean ) => void

	closeModalDialog: boolean
	setCloseModalDialog: ( next: boolean ) => void

	typesTypesList: boolean
	setTypesTypesList: ( next: boolean ) => void

	typesEditOpen: EditId
	setTypesEditOpen: ( next: EditId ) => void

	typesCreateOpen: boolean
	setTypesCreateOpen: ( next: boolean ) => void

	serviceProviderCategories: ReactSelectCreatableCustom[]
	setServiceProviderCategories: ( next: ReactSelectCreatableCustom ) => void

	serviceConsumerCategories: ReactSelectCreatableCustom[]
	setServiceConsumerCategories: ( next: ReactSelectCreatableCustom ) => void

	categories: ReactSelectCreatableCustom[]
	setCategories: ( next: ReactSelectCreatableCustom ) => void

	serviceError: string
	setServiceError: ( next: string ) => void

	updateserviceProviderCategories: ( next: ReactSelectCreatableCustom[] ) => void
	updateserviceConsumerCategories: ( next: ReactSelectCreatableCustom[] ) => void
	draftState: () => void
	resetTaskForms: () => void
}

export const useServiceDialogStore = create<ServiceDialogState>()( ( set ) => ( {
	hasMapped: false,
	setHasMapped: ( next: boolean ) => set( { hasMapped: next } ),
	listOpen: false,
	setListOpen: ( next: boolean ) => set( { listOpen: next } ),
	editOpen: null,
	setEditOpen: ( next: EditId ) => set( { editOpen: next } ),
	editAddressOpen: false,
	setEditAddressOpen: ( next: boolean ) => set( { editAddressOpen: next } ),
	createOpen: false,
	setCreateOpen: ( next: boolean ) => set( { createOpen: next } ),
	closeModalDialog: false,
	setCloseModalDialog: ( next: boolean ) => set( { closeModalDialog: next } ),
	typesTypesList: false,
	setTypesTypesList: ( next: boolean ) => set( { typesTypesList: next } ),
	typesEditOpen: null,
	setTypesEditOpen: ( next: EditId ) => set( { typesEditOpen: next } ),
	typesCreateOpen: false,
	setTypesCreateOpen: ( next: boolean ) => set( { typesCreateOpen: next } ),
	categories: [],
	setCategories: ( next: ReactSelectCreatableCustom ) =>
		set( ( state ) => ( {
			categories: [ ...state.categories, next ],
		} ) ),

	serviceProviderCategories: localStorage.getItem( "providerCategories" )
		? JSON.parse( localStorage.getItem( "providerCategories" ) as string )
		: [],

	serviceError: "",
	setServiceError: ( next: string ) => set( { serviceError: next } ),

	setServiceProviderCategories: ( next: ReactSelectCreatableCustom ) =>
		set( ( state ) => {
			const updatedCategories = [ ...state.serviceProviderCategories, next ]
			const updatedState = {
				serviceProviderCategories: updatedCategories,
			}
			localStorage.setItem( "providerCategories", JSON.stringify( updatedCategories ) )
			return updatedState
		} ),
	updateserviceProviderCategories: ( next: ReactSelectCreatableCustom[] ) =>
		set( () => {
			return {
				serviceProviderCategories: next,
			}
		} ),
	updateserviceConsumerCategories: ( next: ReactSelectCreatableCustom[] ) =>
		set( () => ( {
			serviceConsumerCategories: [ ...next ],
		} ) ),
	serviceConsumerCategories: localStorage.getItem( "consumerCategories" )
		? JSON.parse( localStorage.getItem( "consumerCategories" ) as string )
		: [],
	setServiceConsumerCategories: ( next: ReactSelectCreatableCustom ) =>
		set( ( state ) => {
			const updatedCategories = [ ...state.serviceConsumerCategories, next ]
			const updatedState = {
				serviceConsumerCategories: updatedCategories,
			}
			localStorage.setItem( "consumerCategories", JSON.stringify( updatedCategories ) )
			return updatedState
		} ),
	draftState: () => {
		set( {
			closeModalDialog: false,
			createOpen: false,
		} )
	},
	resetTaskForms: () => {
		set( {
			hasMapped: false,
			closeModalDialog: false,
			createOpen: false,
			categories: [],
			serviceProviderCategories: [],
			serviceConsumerCategories: [],
		} )
		localStorage.removeItem( "consumerCategories" )
		localStorage.removeItem( "providerCategories" )
		localStorage.removeItem( "serviceData" )
	},
} ) )
